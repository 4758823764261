/**
 * WRAPPER
 * Sets a max width, adds a consistent gutter and horizontally
 * centers the contents
 * Info: https://piccalil.li/quick-tip/use-css-clamp-to-create-a-more-flexible-wrapper-utility/
 */
.wrapper {
  max-inline-size: var(--wrapper-max-width, 85rem);
  margin-inline: auto;
  padding-inline: var(--gutter);
}
