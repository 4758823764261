/* 
SIDEBAR
More info: https://every-layout.dev/layouts/sidebar/
A layout that allows you to have a flexible main content area
and a "fixed" width sidebar that sits on the left or right.
If there is not enough viewport space to fit both the sidebar
width *and* the main content minimum width, they will stack
on top of each other

CUSTOM PROPERTIES AND CONFIGURATION
--gutter (var(--space-size-1)): This defines the space
between the sidebar and main content.

--sidebar-target-width (20rem): How large the sidebar should be

--sidebar-content-min-width(50%): The minimum size of the main content area

EXCEPTIONS
.sidebar[data-direction='rtl']: flips the sidebar to be on the right 
*/
.sidebar {
  --flow-space: var(--space-m-l);
  --gutter: var(--space-l-xl);
  display: flex;
  flex-wrap: wrap;
  gap: var(--gutter, var(--space-s-l));
}

.sidebar > :first-child {
  flex-basis: var(--sidebar-target-width, 20rem);
  flex-grow: 1;
}

.sidebar > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: var(--sidebar-content-min-width, 60%);
}

.sidebar.card-light {
  --flow-space: 1em;
  --gutter: var(--space-s-m);
  --sidebar-target-width: var(--space-xl-2xl);
}

.sidebar.card-light img {
  height: var(--space-l-xl);
  width: auto;
}

.sidebar.card-light > * {
  --flow-space: 1em;
}

/* 
A flipped version where the sidebar is on the right
*/
.sidebar[data-direction='rtl'] {
  flex-direction: row-reverse;
}
