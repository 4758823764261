/* Shoelace component custom styling */

.card {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.card-overview {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.card-overview small {
  color: var(--sl-color-neutral-500);
}

.card-overview [slot='footer'] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  
.grid sl-card {
  position: relative;
}

.grid sl-card::part(base) {
  height: 100%; 
}

.grid sl-card::part(body) {
  flex: 1 1 auto;
}