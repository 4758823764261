/* breakout content elements to wrapper based on full bleed image solution by josh cumeau. https://www.joshwcomeau.com/css/full-bleed/ */

.content {
  --wrapper-max-width: 75rem;
  display: grid;
  grid-template-columns:
    1fr
    min(65ch, 100%)
    1fr;
}

.content > * {
  grid-column: 2;
  margin-inline: auto;
}

.full-bleed {
  grid-column: 1 / -1;
  inline-size: 100%;
  background-color: var(--stone-2);
}

.full-bleed * {
  margin-inline: auto;
}
