/* Styling for Shoelace icons and icon-buttons */

  sl-icon-button::part(base):hover,
  sl-icon-button::part(base):focus {
    color: var(--color-primary);
  }

  sl-icon-button::part(base):active {
    color: var(--color-primary);
  }
