.skip-link {
    display: inline-block;
    padding: 0.7rem 1rem 0.5rem 1rem;
    background: var(--color-light);
    color: var(--color-primary-shade);
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  
  .skip-link:hover {
    background: var(--color-dark);
    color: var(--color-light-shade);
  }
  
  .skip-link:not(:focus) {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }
