/* **************** */
/* Open-Props sizes */
/* **************** */

:root {    
    --size-000: -0.5rem;
    --size-00: -0.25rem;
    --size-1: 0.25rem;
    --size-2: 0.5rem;
    --size-3: 1rem;
    --size-4: 1.25rem;
    --size-5: 1.5rem;
    --size-6: 1.75rem;
    --size-7: 2rem;
    --size-8: 3rem;
    --size-9: 4rem;
    --size-10: 5rem;
    --size-11: 7.5rem;
    --size-12: 10rem;
    --size-13: 15rem;
    --size-14: 20rem;
    --size-15: 30rem;
    --size-fluid-1: clamp(0.5rem, 1vw, 1rem);
    --size-fluid-2: clamp(1rem, 2vw, 1.5rem);
    --size-fluid-3: clamp(1.5rem, 3vw, 2rem);
    --size-fluid-4: clamp(2rem, 4vw, 3rem);
    --size-fluid-5: clamp(4rem, 5vw, 5rem);
    --size-fluid-6: clamp(5rem, 7vw, 7.5rem);
    --size-fluid-7: clamp(7.5rem, 10vw, 10rem);
    --size-fluid-8: clamp(10rem, 20vw, 15rem);
    --size-fluid-9: clamp(15rem, 30vw, 20rem);
    --size-fluid-10: clamp(20rem, 40vw, 30rem);
    --size-content-1: 20ch;
    --size-content-2: 45ch;
    --size-content-3: 60ch;
    --size-header-1: 20ch;
    --size-header-2: 25ch;
    --size-header-3: 35ch;

    --font-size-00: .5rem;
    --font-size-0: .75rem;
    --font-size-1: 1rem;
    --font-size-2: 1.1rem;
    --font-size-3: 1.25rem;
    --font-size-4: 1.5rem;
    --font-size-5: 2rem;
    --font-size-6: 2.5rem;
    --font-size-7: 3rem;
    --font-size-8: 3.5rem;
    --font-size-fluid-0: clamp(.75rem, 2vw, 1rem);
    --font-size-fluid-1: clamp(1rem, 4vw, 1.5rem);
    --font-size-fluid-2: clamp(1.5rem, 6vw, 2.5rem);
    --font-size-fluid-3: clamp(2rem, 9vw, 3.5rem);

}