/*
  Global styles

  Low-specificity, global styles that apply to the whole
  project: https://cube.fyi/css.html
*/
body {
  color: var(--color-dark);
  background: var(--color-light);
  font-size: var(--size-step-1);
  font-family: var(--font-sans);
  line-height: 1.4;
  /* letter-spacing: var(--tracking); */
  display: flex;
  flex-direction: column;
}

/* make the footer 'sticky' */
main {
  flex-grow: 1;
}

/* Base typesetting */
h1,
h2,
h3 {
  line-height: 1;
  letter-spacing: var(--tracking-s);
}

h1 {
  font-size: var(--size-step-4);
}

h2 {
  font-size: var(--size-step-3);
}

h3 {
  font-size: var(--size-step-2);
}

/* Set line lengths */
p,
li,
blockquote:not([class]) {
  /* max-inline-size: 50ch; */
}

h1,
h2,
h3 {
  /* max-inline-size: 20ch; */
}

blockquote {
  border-inline-start: 0.8rem solid var(--color-primary);
  padding: var(--space-m-l);
  font-size: var(--size-step-2);
}

blockquote > * + * {
  margin-block-start: var(--space-m-l);
}

blockquote :last-child {
  font-family: var(--font-base);
  font-style: normal;
  font-size: var(--size-step-1);
}

svg {
  block-size: 2ex;
  inline-size: auto;
  flex: none;
}

[role="list"] {
  padding: 0;
}

/* Base interactive elements */

a {
  color: currentcolor;
}

a:hover {
  /* text-decoration: none; */
  /* text-decoration-color: var(--color-primary); */
  text-decoration: underline solid var(--color-primary) 2px;
  text-underline-offset: 0.25em;
  transition: var(--transition-fade);
}

:focus {
  outline: 3px solid;
  outline-offset: 0.3ch;
}

:target {
  scroll-margin-top: 2ex;
}

main:focus {
  outline: none;
}

/* add icon for external links */
article [href^="http"]:not([href*="http://localhost:4000"])::after
{
  display: inline-block;
  inline-size: 1em;
  block-size: 1em;
  /* background-image: var( --external-link, url("/images/svg/external-link-line.svg")); */
  background-image: var(--external-link, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M10 6V8H5V19H16V14H18V20C18 20.5523 17.5523 21 17 21H4C3.44772 21 3 20.5523 3 20V7C3 6.44772 3.44772 6 4 6H10ZM21 3V11H19L18.9999 6.413L11.2071 14.2071L9.79289 12.7929L17.5849 5H13V3H21Z'%3E%3C/path%3E%3C/svg%3E"));
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60% auto;
  /* alternative text rules */
  content: "(external link)";
  overflow: hidden;
  white-space: nowrap;
  text-indent: 1em; /* the width of the icon */
}

/* Base selection styles that invert whatever colours are rendered */
::selection {
  background: var(--color-primary);
  color: var(--color-light);
}

.preload-transitions * {
  transition: none !important;
}
