/* **************** */
/* Open-Props borders */
/* https://open-props.style/#borders */
/* **************** */

:root {
    --radius-1: 2px;
    --radius-2: 5px;
    --radius-3: 1rem;
    --radius-4: 2rem;
    --radius-5: 4rem;
    --radius-6: 8rem;
    --radius-round: 50%;
    --radius-conditional-1: clamp(0px,
            calc(100vw - 100%) * 9999,
            var(--radius-1));
    --radius-conditional-2: clamp(0px,
            calc(100vw - 100%) * 9999,
            var(--radius-2));
    --radius-conditional-3: clamp(0px,
            calc(100vw - 100%) * 9999,
            var(--radius-3));
    --radius-conditional-4: clamp(0px,
            calc(100vw - 100%) * 9999,
            var(--radius-4));
    --radius-conditional-5: clamp(0px,
            calc(100vw - 100%) * 9999,
            var(--radius-5));
    --radius-conditional-6: clamp(0px,
            calc(100vw - 100%) * 9999,
            var(--radius-6));
}

  /* more info about conditional border radius: */
  /* https://ishadeed.com/article/conditional-border-radius/ */