@font-face {
  font-family: "Atkinson Hyperlegible";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(../fonts/atkinson-hyperlegible/Atkinson-Hyperlegible-Regular-102a.woff2)
    format("woff2");
}

@font-face {
  font-family: "Atkinson Hyperlegible";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(../fonts/atkinson-hyperlegible/Atkinson-Hyperlegible-Bold-102a.woff2)
    format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(""), url(../fonts/inter/inter-v7-latin-500.woff2) format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(""), url(../fonts/inter/inter-v7-latin-700.woff2) format("woff2");
}

@font-face {
  font-family: "RobotoMono";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local(""),
    url(../fonts/robotomono/robotomono-variablefont_wght-webfont.woff2)
      format("woff2");
}
