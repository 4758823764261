/* **************** */
/* Colors from Open-Props */
/* https://open-props.style/#colors */
/* **************** */
:root {
    /* Gray */
    --gray-0: #f8f9fa;
    --gray-1: #f1f3f5;
    --gray-2: #e9ecef;
    --gray-3: #dee2e6;
    --gray-4: #ced4da;
    --gray-5: #adb5bd;
    --gray-6: #868e96;
    --gray-7: #495057;
    --gray-8: #343a40;
    --gray-9: #212529;
    --gray-10: #16191d;
    --gray-11: #0d0f12;
    --gray-12: #030507;

    /* Stone */
    --stone-0: #f8fafb;
    --stone-1: #f2f4f6;
    --stone-2: #ebedef;
    --stone-3: #e0e4e5;
    --stone-4: #d1d6d8;
    --stone-5: #b1b6b9;
    --stone-6: #979b9d;
    --stone-7: #7e8282;
    --stone-8: #666968;
    --stone-9: #50514f;
    --stone-10: #3a3a37;
    --stone-11: #252521;
    --stone-12: #121210;

    /* Orange */
    --orange-0: #fff4e6;
    --orange-1: #ffe8cc;
    --orange-2: #ffd8a8;
    --orange-3: #ffc078;
    --orange-4: #ffa94d;
    --orange-5: #ff922b;
    --orange-6: #fd7e14;
    --orange-7: #f76707;
    --orange-8: #e8590c;
    --orange-9: #d9480f;
    --orange-10: #bf400d;
    --orange-11: #99330b;
    --orange-12: #802b09;
}