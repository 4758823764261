.prose {
  --flow-space: var(--space-m-l);
  --wrapper-max-width: 55rem;
}

.prose :is(h2, h3, h4) + * {
  --flow-space: var(--space-s-m);
}

.prose .heading-anchor:is(:hover, :focus) {
  text-decoration: underline;
}
.prose .heading-anchor {
  text-decoration: none;
}

.prose mark {
  background: var(--color-primary-glare);
}

.prose :not(.grid) li + li {
  margin-top: var(--space-s);
}

.prose :is(ul:not(.grid), ol:not(.grid)) {
  padding-inline-start: var(--space-s);
}
