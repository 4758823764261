/* Code to style Snipcart components */


/* Checkout icon in main menu */
.snipcart-svg {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.snipcart-svg:hover {
    fill: var(--color-primary);
}