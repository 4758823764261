.cluster > * {
  display: flex;
  flex-wrap: wrap;
  /* ↓ multiply by -1 to negate the halved value */
  margin: calc(var(--space-s-l) / 2 * -1);
}

.cluster > * > * {
  /* ↓ half the value, because of the 'doubling up' */
  margin: calc(var(--space-s-l) / 2);
}
