footer {
  --external-link: url("/images/svg/external-link-light.svg");
}

.site-foot {
  padding: var(--space-s-m);
  background: var(--color-dark);
  color: var(--color-light);
  font-size: var(--size-step-0);
}

.site-foot__inner {
  display: flex;
  gap: var(--space-s);

}

:not(nav#social).site-foot__inner {
  flex-wrap: wrap;
}

.site-foot * {
  color: var(--color-light);
}

/* #social a {
  display: flex;
} */

#social a::after {
  display: none;
}