.blog h1 {
  font-size: var(--size-step-4);
}

.blog h2 {
  font-size: var(--size-step-3);
}

.blog h3 {
  font-size: var(--size-step-2);
}

.blog img {
  max-inline-size: var(--max-img-width, 100%);
  block-size: auto;
  /* aspect-ratio: 16/9; */
  aspect-ratio: 3/2 auto;
}

.posts .card > img {
    max-inline-size: var(--max-img-width, 100%);
    block-size: auto;
    aspect-ratio: 3/2 auto;
    object-fit: cover;
}