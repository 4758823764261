.product h1 {
    font-size: var(--size-step-3);
}

.product h2 {
    font-size: var(--size-step-2);
}

.product h3 {
    font-size: var(--font-size-fluid-1);
}