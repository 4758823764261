.logo {
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: var(--tracking-s);
  padding: var(--space-xs) 0;
}

.logo:hover {
  text-decoration: none;
}
