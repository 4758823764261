/* Import the base Shoelace stylesheet: */
@import "@shoelace-style/shoelace/dist/themes/light.css";

/* Define CSS Layers */
@layer global, composition, blocks, utilities;

@import "variables/_all.css" layer(global);
@import "global/_all.css" layer(global);
@import "compositions/_all.css" layer(composition);
@import "blocks/_all.css" layer(blocks);
@import "utilities/_all.css" layer(utilities);
